<template>
    <div class="container-fluid">
        <section class="content-header p-1" >
            <div class="row mb-2">
                <h3>Configuración de SIGUE TU EXPEDIENTE</h3>
            </div>
        </section>
        <section class="content">
            <div class="card card-default card-tabs" style="margin:0">
                <div class="card-header p-1 pt-1">
                    <ul class="nav nav-pills">
                        <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
                            <a
                                class="nav-link"
                                :class="{ active: activeTab === tab.id }"
                                @click.prevent="activeTab = tab.id"
                                :href="'#' + tab.id"
                            >
                                {{ tab.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="tab-content" style=" margin-top: 15px; padding-bottom: 15px;">
                <component
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :is="tab.component"
                    :class="['tab-pane', 'container-grid', { active: activeTab === tab.id }]"
                ></component>
            </div>
        </section>
    </div>
</template>

<script>
import OpcionesTab from './ste_opciones.vue';
import CompaniasTab from './ste_companias.vue';
import LineasDeCompaniasTab from './ste_lineasdecompania.vue';
import SmsTab from './ste_sms.vue';
import EmailTab from './ste_email.vue';
import EncuestaTab from './ste_encuesta.vue';
import ConsultasTab from './ste_consultas.vue';
import PagosTpvTab from './ste_pagostpv.vue';
import TpvTab from './ste_tpv.vue';

export default {
    components: {
        OpcionesTab,
        CompaniasTab,
        LineasDeCompaniasTab,
        SmsTab,
        EmailTab,
        EncuestaTab,
        ConsultasTab,
        PagosTpvTab,
        TpvTab,
    },
    data() {
        return {
            activeTab: 'opciones',
            tabs: [
                { id: 'opciones', name: 'Opciones', component: 'OpcionesTab' },
                { id: 'compañias', name: 'Compañías', component: 'CompaniasTab' },
                { id: 'lineasdecompañias', name: 'Líneas de compañías', component: 'LineasDeCompaniasTab' },
                { id: 'sms', name: 'SMS', component: 'SmsTab' },
                { id: 'email', name: 'Email', component: 'EmailTab' },
                { id: 'encuesta', name: 'Encuesta', component: 'EncuestaTab' },
                { id: 'consultas', name: 'Consultas', component: 'ConsultasTab' },
                { id: 'pagostpv', name: 'Pagos TPV', component: 'PagosTpvTab' },
                { id: 'tpv', name: 'TPV', component: 'TpvTab' },
            ]
        };
    }
};
</script>

<style scoped>
.nav-link.active {
    background-color: #007bff;
    color: white;
}

.tab-pane {
    display: none;
}

.tab-pane.active {
    display: block;
}
</style>
